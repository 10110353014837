<template>
  <main>
    <div class="auth-container">
      <img src="../assets/auth-logo.png" alt="Запчасти для иностранных грузовиков и прицепов" class="auth-container__logo">
      <ThisComponent>
        <h1>Личный кабинет участника</h1>
        <p>Добро пожаловать на каталог поощрений и вознаграждений компании <b>"БалтКам"</b>!</p>
        <p>Вам понадобится не более одной минуты, чтобы выбрать себе подарок.</p>
        <hr>
      </ThisComponent>
    </div>
  </main>
</template>

<script>
import ThisComponent from 'base/components/AuthorizationSmsCode'

ThisComponent.mounted = function () {
  this.$store.commit('app/updatePageClass', 'page--auth')

  this.afterMounted()
}

export default {
  components: {
    ThisComponent
  }
}
</script>

<style lang="css" scoped>
  main {
    margin: auto auto 0;
    display: flex;
    align-items: center;
  }

  .auth-container {
    display: flex;
    align-items: center;
  }

  @media (max-width: 992px) {
    .auth-container__logo {
      display: none;
    }
  }

  h1 {
    font-size: 1.8em;
    margin-top: 0;
  }

  .form {
    margin-bottom: 20px;
    background: rgba(255, 255, 255, 0.9);
    padding: 20px;
    border-radius: var(--border-radius);
    max-width: 520px;
  }

  .step__fields .form-group {
    margin-bottom: 10px;
  }

  .form-field {
    width: 100%;
  }

  .form .button {
    white-space: nowrap;
  }

  @media (min-width: 480px) {
    .step__fields {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
    }

    .step__fields .form-group {
      margin-right: 10px;
      margin-bottom: 0;
    }
  }
</style>
